import { APIResponse } from "../model/apiresponse";
import { Area } from "../model/area";
import { AreaDataI } from "../model/area";
import { Usuario } from "../model/usuario";
import axios from "axios";
import Global from "../global/global";

export class APIArea {
  async _listarAreasPorUsuario(
    _usuario: Usuario,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(
        `${Global.URL_API}listarAreasPorUsuario`,
        {
          usuario: _usuario.codigo,
          token: _token,
        }
      );
      if (_result.status === 200) {
        if (_result.data.success) {
          let _areas: Area[] = [];
          _result.data.data.forEach((_area: AreaDataI) => {
            _areas.push(new Area(_area));
          });
          _response = {
            success: _result.data.success,
            data: _areas,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _obtenerArea(_codigo: string, _token: string): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}obtenerArea`, {
        codigo: _codigo,
        token: _token,
      });
      if (_result.status === 200) {
        if (_result.data.success) {
          let _area: Area = new Area(_result.data.data[0]);
          _response = {
            success: _result.data.success,
            data: _area,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }
}
