function NotFoundComponent() {
  return (
    <div className="center-text">
      <h1>404</h1>
      <h1>No se econtr&oacute; el contenido solicitado</h1>
      <h2>Se lo llevaron los extraterrestres</h2>
    </div>
  );
}

export default NotFoundComponent;
