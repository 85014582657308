import "../../styles/widgets/documento.widget.scss";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Documento } from "../../model/documento";
import { Fragment } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Space } from "antd";
import Moment from "moment";

function DocumentoWidget({
  _documento,
  _renderArchivos,
}: {
  _documento: Documento;
  _renderArchivos: Function;
}) {
  return (
    <Fragment>
      <div className="div-main-documento">
        <div className="nombre-documento">{_documento.documento}</div>
        <div className="detalle-documento">
          <span>
            <b>Tipo: </b>
            {_documento.tipo.tipo}
          </span>
          <br />
          <span>
            <b>Versi&oacute;n: </b>
            {_documento.version}
          </span>
          <br />
          <span>
            <b>Vigencia: </b>
            {Moment(_documento.vigencia).format("DD-MM-YYYY")}
          </span>
          <br />
        </div>
        <div className="footer-documento">{_documento.nombre}</div>
        <Space direction="vertical">
          {_renderArchivos(_documento)}
          <Space>
            {_documento.ultimo_acceso
              ? Moment(_documento.ultimo_acceso).format("DD-MM-YYYY h:m:s")
              : ""}
            {_documento.descargado === 1 ? (
              <CheckCircleOutlined className="descargado" />
            ) : (
              <InfoCircleOutlined className="no-descargado" />
            )}
          </Space>
        </Space>
      </div>
    </Fragment>
  );
}

export default DocumentoWidget;
