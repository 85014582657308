import nodata from "../../assets/nodata.svg"

function NoDataComponent() {
  return (
    <div>
      <img src={nodata} alt="No hay datos para mostrar" title="No hay datos para mostrar" style={{ width: 200, margin: 20 }} />
      <h2><b>No hay datos para mostrar</b></h2>
    </div>
  );
}

export default NoDataComponent;
