import { Col } from "antd";
import { Form } from "antd";
import { FormInstance } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Row } from "antd";

function CambioPasswordWidget({
  _form,
  _load,
}: {
  _form: FormInstance;
  _load: boolean;
}) {
  return (
    <Fragment>
      <Form
        id="form-cambio-password"
        name="form-cambio-password"
        className="form-cambio-password"
        layout="vertical"
        autoComplete="off"
        form={_form}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Contraseña actual"
              name="password_actual"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa tu contraseña actual!",
                },
              ]}
            >
              <Input.Password size="middle" readOnly={_load ? true : false} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Contraseña nueva"
              name="password_new"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa tu contraseña nueva!",
                },
              ]}
            >
              <Input.Password size="middle" readOnly={_load ? true : false} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Repetir contraseña nueva"
              name="password_new_repeat"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa tu contraseña nueva!",
                },
              ]}
            >
              <Input.Password size="middle" readOnly={_load ? true : false} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
}

export default CambioPasswordWidget;
