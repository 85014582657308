import "../../styles/widgets/proceso.widget.scss";
import { Proceso } from "../../model/proceso";

function ProcesoWidget({
  _proceso,
  _toSubprocesos,
}: {
  _proceso: Proceso;
  _toSubprocesos: Function;
}) {
  return (
    <div
      className="div-proceso"
      onClick={() => {
        _toSubprocesos(_proceso);
      }}
    >
      {_proceso.proceso}
      {parseInt(_proceso.pendientes) > 0 ? (
        <div className="notificacion">{parseInt(_proceso.pendientes)}</div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ProcesoWidget;
