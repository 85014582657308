import { APIResponse } from "../model/apiresponse";
import { Documento } from "../model/documento";
import { DocumentoDataI } from "../model/documento";
import axios from "axios";
import Global from "../global/global";

export class APIDocumento {
  async _listarDocumentosPorUsuario(
    _usuario: number,
    _subproceso: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(
        `${Global.URL_API}listarDocumentosPorUsuario`,
        {
          usuario: _usuario,
          subproceso: _subproceso,
          token: _token,
        }
      );
      if (_result.status === 200) {
        if (_result.data.success) {
          let _documentos: Documento[] = [];
          _result.data.data.forEach((_documento: DocumentoDataI) => {
            _documentos.push(new Documento(_documento));
          });
          _response = {
            success: _result.data.success,
            data: _documentos,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _listarTodosDocumentosPorUsuario(
    _usuario: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(
        `${Global.URL_API}listarTodosDocumentosPorUsuario`,
        {
          usuario: _usuario,
          token: _token,
        }
      );
      if (_result.status === 200) {
        if (_result.data.success) {
          let _documentos: Documento[] = [];
          _result.data.data.forEach((_documento: DocumentoDataI) => {
            _documentos.push(new Documento(_documento));
          });
          _response = {
            success: _result.data.success,
            data: _documentos,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }


  async _obtenerDocumento(
    _codigo: string,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}obtenerDocumento`, {
        codigo: _codigo,
      });
      if (_result.status === 200) {
        if (_result.data.success) {
          let _documento: Documento = new Documento(_result.data.data[0]);
          _response = {
            success: _result.data.success,
            data: _documento,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _marcarDocumento(
    _usuario: number,
    _documento: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}marcarDocumento`, {
        documento: _documento,
        usuario: _usuario,
        token: _token,
      });
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }
}
