import { APIResponse } from "../model/apiresponse";
import { Subproceso } from "../model/subproceso";
import { SubprocesoDataI } from "../model/subproceso";
import axios from "axios";
import Global from "../global/global";

export class APISubproceso {
  async _listarSubprocesosPorUsuario(
    _usuario: number,
    _proceso: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(
        `${Global.URL_API}listarSubprocesosPorUsuario`,
        {
          proceso: _proceso,
          usuario: _usuario,
          token: _token,
        }
      );
      if (_result.status === 200) {
        if (_result.data.success) {
          let _subprocesos: Subproceso[] = [];
          _result.data.data.forEach((_proceso: SubprocesoDataI) => {
            _subprocesos.push(new Subproceso(_proceso));
          });
          _response = {
            success: _result.data.success,
            data: _subprocesos,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _obtenerSubproceso(
    _codigo: string,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(
        `${Global.URL_API}obtenerSubproceso`,
        {
          codigo: _codigo,
          token: _token,
        }
      );
      if (_result.status === 200) {
        if (_result.data.success) {
          let _area: Subproceso = new Subproceso(_result.data.data[0]);
          _response = {
            success: _result.data.success,
            data: _area,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }
}
