import "../../styles/pages/areas.page.scss";
import { _getSession } from "./dashboard.page";
import { APIArea } from "../../api/api_area";
import { APIDocumento } from "../../api/api_documento";
import { APIResponse } from "../../model/apiresponse";
import { Area } from "../../model/area";
import { Documento } from "../../model/documento";
import { Col, Popover } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { List } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import AreaContext from "../../provider/area-provider";
import AreaWidget from "../widgets/area.widget";
import DocumentoContext from "../../provider/documento-provider";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import NoDataComponent from "../components/nodata.component";

function AreasPage() {
  const [_areas, _setAreas] = useState<Area[]>([]);
  const [_documentos, _setDocumentos] = useState<Documento[]>([]);
  const [_documentosTemp, _setDocumentosTemp] = useState<Documento[]>([]);
  const [_exists, _setExists] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_visible, _setVisible] = useState<boolean>(false);
  const _apiArea = useContext<APIArea>(AreaContext);
  const _apiDocumento = useContext<APIDocumento>(DocumentoContext);
  const { _token } = _getSession();
  const { _usuario } = _getSession();
  let _navigate = useNavigate();

  const _listarAreasPorUsuario = useCallback(
    async (_usuario: Usuario, _token: string) => {
      let _result: APIResponse = await _apiArea._listarAreasPorUsuario(
        _usuario,
        _token
      );
      if (_result.codigo === 401) {
        _modal.error({
          title: Global.NOMBRE_PROYECTO,
          content: _result.message,
          centered: true,
          onOk: () => {
            localStorage.clear();
            _navigate("/login");
          },
        });
      } else if (_result.success) {
        _setExists(true);
        _setAreas(_result.data);
      } else {
        _setExists(false);
        _setAreas([]);
      }
    },
    [_apiArea, _modal, _navigate]
  );

  const _toProcesos = (_area: Area) => {
    _navigate(`/${_area.area.toLocaleLowerCase()}/${_area.codigo}`);
  };

  const _listarDocumentosPorUsuario = useCallback(
    async (_usuario: Usuario) => {
      let _result: APIResponse =
        await _apiDocumento._listarTodosDocumentosPorUsuario(
          _usuario.codigo,
          _token
        );
      if (_result.codigo === 401) {
        _modal.error({
          title: Global.NOMBRE_PROYECTO,
          content: _result.message,
          centered: true,
          onOk: () => {
            localStorage.clear();
            _navigate("/login");
          },
        });
      } else if (_result.success) {
        _setExists(true);
        _setDocumentos(_result.data);
        _setDocumentosTemp(_result.data);
      } else {
        _setExists(false);
        _setDocumentos([]);
        _setDocumentosTemp([]);
      }
    },
    [_apiDocumento, _modal, _navigate, _token]
  );

  useEffect(() => {
    _setMounted(true);
    _listarAreasPorUsuario(_usuario, _token);
    _listarDocumentosPorUsuario(_usuario);
    return () => {
      _setMounted(false);
    };
  }, [_listarAreasPorUsuario, _listarDocumentosPorUsuario, _token, _usuario]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Row>
        {!_exists ? (
          <NoDataComponent />
        ) : _areas.length > 0 ? (
          <Fragment>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Popover
                  content={
                    <List
                      itemLayout="horizontal"
                      size="small"
                      pagination={{
                        pageSize: 5,
                        size: "small",
                      }}
                      dataSource={_documentos}
                      renderItem={(_documento: Documento) => (
                        <List.Item
                          key={_documento.codigo}
                          onClick={() => {
                            _navigate(
                              `/${_documento.subproceso.proceso.area.area.toLocaleLowerCase()}/${_documento.subproceso.proceso.proceso.toLocaleLowerCase()} /${_documento.subproceso.subproceso.toLocaleLowerCase()}/${
                                _documento.subproceso.codigo
                              }`
                            );
                          }}
                        >
                          <List.Item.Meta
                            title={_documento.nombre}
                            description={`${_documento.subproceso.proceso.area.area} > ${_documento.subproceso.proceso.proceso} > ${_documento.subproceso.subproceso} > ${_documento.documento}`}
                          />
                        </List.Item>
                      )}
                    />
                  }
                  placement="bottom"
                  title="Resultados de la búsqueda"
                  trigger="click"
                  visible={_visible}
                  onVisibleChange={() => {
                    _setVisible(!_visible);
                  }}
                  overlayStyle={{ width: "80%" }}
                >
                  <Input
                    placeholder="Ingresa el nombre del documento ..."
                    onChange={(_e: any) => {
                      if (_e.target.value) {
                        _setVisible(true);
                        _setDocumentos(
                          _documentosTemp.filter(
                            (_documento: Documento, _index: number) => {
                              return (
                                _documento.nombre
                                  .toLocaleLowerCase()
                                  .indexOf(
                                    _e.target.value.toLocaleLowerCase()
                                  ) >= 0
                              );
                            }
                          )
                        );
                      } else {
                        _setVisible(false);
                        _setDocumentos(_documentosTemp);
                      }
                    }}
                    style={{ width: "80%", margin: "20px auto" }}
                    prefix={<SearchOutlined />}
                    allowClear
                  />
                </Popover>
              </Col>
            </Row>
            <Row>
              {_areas.map((_area: Area, _index: number) => {
                return (
                  <Col
                    key={`area${_index}`}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    xxl={6}
                  >
                    <AreaWidget _area={_area} _toProcesos={_toProcesos} />
                  </Col>
                );
              })}
            </Row>
          </Fragment>
        ) : (
          <LoadComponent />
        )}
      </Row>
      {_contextHolder}
    </Fragment>
  );
}

export default AreasPage;
