import { APIResponse } from "../model/apiresponse";
import { Usuario } from "../model/usuario";
import axios from "axios";
import Global from "../global/global";

export class APIUsuario {
  async _login(_correo: string, _password: string): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}login`, {
        correo: _correo,
        password: _password,
      });
      if (_result.status === 200) {
        if (_result.data.success) {
          let _usuario: Usuario = new Usuario(_result.data.data);
          let _token: string = _result.data.token;
          if (_usuario.cambio_clave === 1) {
            this._guardarToken(_result.data.token, _usuario);
          }
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            data: _usuario,
            token: _token,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _cambioPassword(
    _usuario: Usuario,
    _password: string,
    _password_actual: string,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.put(`${Global.URL_API}cambioPassword`, {
        codigo: _usuario.codigo,
        password: _password,
        password_actual: _password_actual,
        token: _token,
      });
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
        };
        if (_response.success) {
          this._guardarToken(_token, _usuario);
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  _guardarToken = (_token: string, _usuario: Usuario) => {
    localStorage.setItem(Global.TOKEN, JSON.stringify(_token));
    localStorage.setItem(Global.USUARIO_SESSION, JSON.stringify(_usuario));
  };
}
