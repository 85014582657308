import { Area } from "./area";

export interface ProcesoDataI {
  codigo: number;
  proceso: string;
  estado: number;
  pendientes: string;
  area: Area;
}

export class Proceso implements ProcesoDataI {
  codigo: number;
  proceso: string;
  estado: number;
  pendientes: string;
  area: Area;

  constructor(data: ProcesoDataI) {
    this.codigo = data.codigo;
    this.proceso = data.proceso;
    this.estado = data.estado;
    this.pendientes = data.pendientes;
    this.area = data.area;
  }
}
