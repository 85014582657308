import "../../styles/widgets/area.widget.scss";
import { Area } from "../../model/area";

function AreaWidget({
  _area,
  _toProcesos,
}: {
  _area: Area;
  _toProcesos: Function;
}) {
  return (
    <div
      className="div-area"
      onClick={() => {
        _toProcesos(_area);
      }}
    >
      {_area.area}
      {parseInt(_area.pendientes) > 0 ? (
        <div className="notificacion">{parseInt(_area.pendientes)}</div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AreaWidget;
