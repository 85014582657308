import "../../styles/pages/login.page.scss";
import { APIResponse } from "../../model/apiresponse";
import { APIUsuario } from "../../api/api_usuario";
import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { Row } from "antd";
import { Spin } from "antd";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import CambioPasswordWidget from "../widgets/cambio_password.widget";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import logo from "../../assets/logo_azul.svg";
import UsuarioContext from "../../provider/usuario-provider";

function LoginPage() {
  const [_form] = Form.useForm();
  const [_load, _setLoad] = useState<boolean>(false);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_token, _setToken] = useState<string>("");
  const [_usuario, _setUsuario] = useState<Usuario>();
  const [_visiblePassword, _setVisiblePassword] = useState<boolean>(false);
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { Title } = Typography;
  let _navigate = useNavigate();

  const _getCurrentUser = useCallback(async () => {
    if (
      localStorage.getItem(Global.USUARIO_SESSION) &&
      localStorage.getItem(Global.TOKEN)
    ) {
      _navigate("/");
    }
  }, [_navigate]);

  const _login = async (values: any) => {
    _setLoad(true);
    let _result: APIResponse = await _apiUsuario._login(
      values["correo"],
      values["password"]
    );
    if (_result.success) {
      let _usuario: Usuario = new Usuario(_result.data);
      _setUsuario(_usuario);
      _setToken(_result.token);
      if (_usuario.cambio_clave === 1) {
        _modal.success({
          title: Global.NOMBRE_PROYECTO,
          content: _result.message,
          centered: true,
          direction: "ltr",
          onOk: () => {
            _setLoad(false);
            _navigate("/");
          },
        });
      } else {
        _setLoad(false);
        _setVisiblePassword(true);
      }
    } else {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        direction: "ltr",
        onOk: () => {
          _setLoad(false);
        },
      });
    }
  };

  const _cambioPassword = async (
    _values: any,
    _usuario: Usuario,
    _token: string
  ) => {
    _setLoad(true);
    let _passwordActual: string = _values["password_actual"];
    let _passwordNew: string = _values["password_new"];
    let _passwordNewRepeat: string = _values["password_new_repeat"];

    if (_passwordNew !== _passwordNewRepeat) {
      _modal.warning({
        title: Global.NOMBRE_PROYECTO,
        content: "Las contraseñas no coinciden",
        centered: true,
        direction: "ltr",
        onOk: () => {
          _setLoad(false);
        },
      });
    } else {
      _modal.confirm({
        title: Global.NOMBRE_PROYECTO,
        okText: "Si, cambiar",
        cancelText: "No, cancelar",
        content: "¿Está seguro de cambiar su contraseña?",
        centered: true,
        onCancel: () => {
          _setLoad(false);
        },
        onOk: async (_e: any) => {
          let _result: APIResponse = await _apiUsuario._cambioPassword(
            _usuario,
            _passwordNew,
            _passwordActual,
            _token
          );
          if (_result.success) {
            _modal.success({
              title: Global.NOMBRE_PROYECTO,
              content: _result.message,
              centered: true,
              direction: "ltr",
              onOk: () => {
                _setLoad(false);
                _navigate("/");
              },
            });
          } else {
            _modal.error({
              title: Global.NOMBRE_PROYECTO,
              content: _result.message,
              centered: true,
              direction: "ltr",
              onOk: () => {
                _setLoad(false);
              },
            });
          }
        },
      });
    }
  };

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <div className="container-login">
        <Row className="card-login">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <img src={logo} alt="3AAMSEQ" title="3AAMSEQ" loading="lazy" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Title level={4}>GESTI&Oacute;N DE DOCUMENTOS</Title>
            <Title level={5}>
              Inicio de sesi&oacute;n <br />
              Ingresa los datos solicitados para iniciar sesi&oacute;n
            </Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form
              name="form-login"
              className="form-login"
              layout="vertical"
              onFinish={_login}
            >
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label="Correo"
                    name="correo"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor ingresa tu correo!",
                        type: "email",
                      },
                    ]}
                  >
                    <Input size="middle" readOnly={_load ? true : false} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor ingresa tu contraseña!",
                      },
                    ]}
                  >
                    <Input.Password
                      size="middle"
                      readOnly={_load ? true : false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item>
                    {_load ? (
                      <Spin
                        className="spin-yellow"
                        tip="Validando los datos ingresados..."
                      />
                    ) : (
                      <Button
                        htmlType="submit"
                        className="button-yellow"
                        icon={<LoginOutlined />}
                      >
                        Iniciar sesi&oacute;n
                      </Button>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <span>
                    El acceso al sistema estar&aacute; disponible de 7:00 a. m.
                    a 7:00 p. m.
                  </span>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {/* Modal cambio de contraseña */}
        <Modal
          title={<b>Cambio de contraseña</b>}
          visible={_visiblePassword}
          centered={true}
          closable={false}
          cancelText="Cancelar"
          okText="Cambiar"
          onCancel={() => {
            if (!_load) {
              _setLoad(false);
              _setVisiblePassword(false);
            }
          }}
          okButtonProps={{
            loading: _load,
          }}
          onOk={(_e: any) => {
            _form
              .validateFields()
              .then((_values: any) => {
                _cambioPassword(_values, _usuario!, _token);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <CambioPasswordWidget _form={_form} _load={_load} />
        </Modal>
        {_contextHolder}
      </div>
    </Fragment>
  );
}

export default LoginPage;
