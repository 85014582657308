import "../../styles/pages/subprocesos.page.scss";
import { _getSession } from "./dashboard.page";
import { APIProceso } from "../../api/api_proceso";
import { APIResponse } from "../../model/apiresponse";
import { APISubproceso } from "../../api/api_subproceso";
import { Col } from "antd";
import { Fragment } from "react";
import { Modal } from "antd";
import { Proceso } from "../../model/proceso";
import { Row } from "antd";
import { Subproceso } from "../../model/subproceso";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import NoDataComponent from "../components/nodata.component";
import ProcesoContext from "../../provider/proceso-provider";
import SubprocesoContext from "../../provider/subproceso-provider";
import SubprocesoWidget from "../widgets/subproceso.widget";

function SubprocesoPage() {
  const [_exists, _setExists] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_proceso, _setProceso] = useState<Proceso>();
  const [_subprocesos, _setSubprocesos] = useState<Subproceso[]>([]);
  const _apiProceso = useContext<APIProceso>(ProcesoContext);
  const _apiSubproceso = useContext<APISubproceso>(SubprocesoContext);
  const { _codigo } = useParams();
  const { _token } = _getSession();
  const { _usuario } = _getSession();
  const { Title } = Typography;
  let _navigate = useNavigate();

  const _obtenerProceso = useCallback(async () => {
    let _result: APIResponse = await _apiProceso._obtenerProceso(
      _codigo!,
      _token
    );
    if (_result.success) {
      _setProceso(_result.data);
    }
  }, [_apiProceso, _codigo, _token]);

  const _listarSubprocesosPorUsuario = useCallback(
    async (_usuario: Usuario) => {
      let _result: APIResponse =
        await _apiSubproceso._listarSubprocesosPorUsuario(
          _usuario.codigo,
          parseInt(_codigo!),
          _token
        );
      if (_result.codigo === 401) {
        _modal.error({
          title: Global.NOMBRE_PROYECTO,
          content: _result.message,
          centered: true,
          onOk: () => {
            localStorage.clear();
            _navigate("/login");
          },
        });
      } else if (_result.success) {
        _setExists(true);
        _setSubprocesos(_result.data);
      } else {
        _setExists(false);
        _setSubprocesos([]);
      }
    },
    [_apiSubproceso, _codigo, _modal, _navigate, _token]
  );

  const _toDocumentos = (_subproceso: Subproceso) => {
    _navigate(
      `/${_subproceso.proceso.area.area.toLocaleLowerCase()}/${_subproceso.proceso.proceso.toLocaleLowerCase()} /${_subproceso.subproceso.toLocaleLowerCase()}/${
        _subproceso.codigo
      }`
    );
  };

  useEffect(() => {
    _setMounted(true);
    _obtenerProceso();
    _listarSubprocesosPorUsuario(_usuario);
    return () => {
      _setMounted(false);
    };
  }, [_listarSubprocesosPorUsuario, _obtenerProceso, _usuario]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={5}>Proceso: {_proceso ? _proceso.proceso : ""}</Title>
          <Title level={2} className="nombre-proceso-subprocesos">
            {_proceso ? _proceso.proceso : ""}
          </Title>
        </Col>
        <div className="div-subprocesos">
          <Row>
            {!_exists ? (
              <NoDataComponent />
            ) : _subprocesos.length > 0 ? (
              _subprocesos.map((_subproceso: Subproceso, _index: number) => {
                return (
                  <Col
                    key={`subproceso${_index}`}
                    xs={12}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={6}
                    xxl={6}
                  >
                    <SubprocesoWidget
                      _subproceso={_subproceso}
                      _toDocumentos={_toDocumentos}
                    />
                  </Col>
                );
              })
            ) : (
              <LoadComponent />
            )}
          </Row>
        </div>
      </Row>
      {_contextHolder}
    </Fragment>
  );
}

export default SubprocesoPage;
