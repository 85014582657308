export interface AreaDataI {
  codigo: number;
  area: string;
  estado: number;
  pendientes: string;
}

export class Area implements AreaDataI {
  codigo: number;
  area: string;
  estado: number;
  pendientes: string;

  constructor(data: AreaDataI) {
    this.codigo = data.codigo;
    this.area = data.area;
    this.estado = data.estado;
    this.pendientes = data.pendientes;
  }
}
