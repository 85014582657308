import "../../styles/pages/dashboard.page.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Col } from "antd";
import { Fragment } from "react";
import { HomeOutlined } from "@ant-design/icons";
import { LogoutOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { Outlet } from "react-router-dom";
import { Row } from "antd";
import { Space } from "antd";
import { useCallback } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import logo from "../../assets/logo_azul.svg";
import Moment from "moment";

type ContextType = { _usuario: Usuario; _token: string };
export function _getSession() {
  return useOutletContext<ContextType>();
}

function DashboardPage() {
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_token, _setToken] = useState<string>();
  const [_usuario, _setUsuario] = useState<Usuario>();
  let _navigate = useNavigate();

  const _getCurrentUser = useCallback(async () => {
    if (
      localStorage.getItem(Global.USUARIO_SESSION) &&
      localStorage.getItem(Global.TOKEN)
    ) {
      _setUsuario(
        new Usuario(JSON.parse(localStorage.getItem(Global.USUARIO_SESSION)!))
      );
      _setToken(localStorage.getItem(Global.TOKEN)!);
    } else {
      _navigate("/login");
    }
  }, [_navigate]);

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <div className="container-dashboard">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <img
              src={logo}
              alt="3AAMSEQ"
              title="3AAMSEQ"
              loading="lazy"
              className="logo-dashboard"
            />
            <br />
            <b>{`Empresa: ${_usuario!.empresa.empresa}`}</b>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Space direction="vertical">
              <span>
                <b>
                  {`
                  Sede: ${_usuario!.sede.sede} | 
                  Puesto: ${_usuario!.puesto.puesto} |
                  Usuario: ${_usuario!.nombres} | 
                  Último acceso: ${
                    Moment(_usuario!.ultimo_acceso).format(
                      "DD-MM-YYYY h:m:s"
                    ) ?? "Este es tu primer acceso"
                  }
                  `}
                </b>
              </span>
              <Space>
                <Button
                  icon={<ArrowLeftOutlined />}
                  className="button-dashboard"
                  onClick={() => {
                    _navigate(-1);
                  }}
                >
                  Volver
                </Button>
                <Button
                  icon={<HomeOutlined />}
                  className="button-dashboard"
                  onClick={() => {
                    _navigate("/");
                  }}
                >
                  Inicio
                </Button>
                <Button
                  icon={<LogoutOutlined />}
                  className="button-dashboard"
                  onClick={() => {
                    _modal.confirm({
                      title: Global.NOMBRE_PROYECTO,
                      content: "¿Está seguro de cerrar su sesión?",
                      centered: true,
                      okText: "Si, cerrar",
                      cancelText: "No, cancelar",
                      onOk: () => {
                        localStorage.clear();
                        _navigate("/login");
                      },
                    });
                  }}
                >
                  Salir
                </Button>
              </Space>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="col-content-dashboard"
          >
            <Outlet context={{ _usuario, _token }} />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            {Global.NOMBRE_PROYECTO} ©2022 Creado por TI
          </Col>
        </Row>
      </div>
      {_contextHolder}
    </Fragment>
  );
}

export default DashboardPage;
