import { APIResponse } from "../model/apiresponse";
import { Proceso } from "../model/proceso";
import { ProcesoDataI } from "../model/proceso";
import axios from "axios";
import Global from "../global/global";

export class APIProceso {
  async _listarProcesosPorUsuario(
    _usuario: number,
    _area: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(
        `${Global.URL_API}listarProcesosPorUsuario`,
        {
          area: _area,
          usuario: _usuario,
          token: _token,
        }
      );
      if (_result.status === 200) {
        if (_result.data.success) {
          let _procesos: Proceso[] = [];
          _result.data.data.forEach((_proceso: ProcesoDataI) => {
            _procesos.push(new Proceso(_proceso));
          });
          _response = {
            success: _result.data.success,
            data: _procesos,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _obtenerProceso(_codigo: string, _token: string): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}obtenerProceso`, {
        codigo: _codigo,
        token: _token,
      });
      if (_result.status === 200) {
        if (_result.data.success) {
          let _area: Proceso = new Proceso(_result.data.data[0]);
          _response = {
            success: _result.data.success,
            data: _area,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }
}
