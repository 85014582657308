import { BrowserRouter } from "react-router-dom";
import { Fragment } from "react";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import AreasPage from "./areas.page";
import DashboardPage from "./dashboard.page";
import DocumentosPage from "./documentos.page";
import ErrorComponent from "../components/error.component";
import LoadComponent from "../components/load.component";
import LoginPage from "./login.page";
import NotFoundComponent from "../components/notfound.component";
import ProcesosPage from "./procesos.page";
import SubprocesosPage from "./subprocesos.page";

function AppPage() {
  const [_mounted, _setMounted] = useState<any>(null);
  useEffect(() => {
    _setMounted(true);
    return () => {
      _setMounted(false);
    };
  }, []);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<DashboardPage />}>
            <Route index element={<AreasPage />} />
            <Route path="areas" element={<AreasPage />} />
            <Route path=":_area/:_codigo" element={<ProcesosPage />} />
            <Route path=":_area/:_proceso/:_codigo" element={<SubprocesosPage />} />
            <Route path=":_area/:_proceso/:_subproceso/:_codigo" element={<DocumentosPage />} />
            <Route path="*" element={<NotFoundComponent />} />
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<NotFoundComponent />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default AppPage;
