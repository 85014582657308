import { Spin } from "antd";

function LoadComponent() {
  return (
    <div>
      <Spin className="spin-success" />
    </div>
  );
}

export default LoadComponent;
