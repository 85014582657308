export interface APIDataI {
  success?: boolean;
  codigo?: number;
  message?: string;
  data?: any;
  token?: any;
}

export class APIResponse implements APIDataI {
  success?: boolean;
  codigo?: number;
  message?: string;
  data?: any;
  token?: any;

  constructor(data: APIDataI) {
    this.success = data.success;
    this.codigo = data.codigo;
    this.message = data.message;
    this.data = data.data;
    this.token = data.token;
  }
}
