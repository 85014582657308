import { Archivo } from "./archivo";
import { Subproceso } from "./subproceso";
import { Tipo } from "./tipo";
export interface DocumentoDataI {
  codigo: number;
  documento: string;
  version: string;
  nombre: string;
  estado: number;
  fecha: string;
  vigencia: string;
  tipo: Tipo;
  archivos: Archivo[];
  subproceso: Subproceso;
  descargado: number;
  ultimo_acceso: string;
}

export class Documento implements DocumentoDataI {
  codigo: number;
  documento: string;
  version: string;
  nombre: string;
  estado: number;
  fecha: string;
  vigencia: string;
  tipo: Tipo;
  archivos: Archivo[];
  subproceso: Subproceso;
  descargado: number;
  ultimo_acceso: string;

  constructor(data: DocumentoDataI) {
    this.codigo = data.codigo;
    this.documento = data.documento;
    this.version = data.version;
    this.nombre = data.nombre;
    this.estado = data.estado;
    this.fecha = data.fecha;
    this.vigencia = data.vigencia;
    this.tipo = data.tipo;
    this.archivos = data.archivos;
    this.subproceso = data.subproceso;
    this.descargado = data.descargado;
    this.ultimo_acceso = data.ultimo_acceso;
  }
}
