import "../../styles/widgets/subproceso.widget.scss";
import { Fragment } from "react";
import { Subproceso } from "../../model/subproceso";

function SubprocesoWidget({
  _subproceso,
  _toDocumentos,
}: {
  _subproceso: Subproceso;
  _toDocumentos: Function;
}) {
  return (
    <Fragment>
      <div
        className="div-main-subproceso"
        onClick={() => {
          _toDocumentos(_subproceso);
        }}
      >
        <div className="div-subproceso">
          <div className="cuadrito-subproceso">
            {parseInt(_subproceso.pendientes) > 0 ? (
              <div className="notificacion">
                {parseInt(_subproceso.pendientes)}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="nombre-subproceso">{_subproceso.subproceso}</div>
      </div>
    </Fragment>
  );
}

export default SubprocesoWidget;
