import "../../styles/pages/documentos.page.scss";
import { _getSession } from "./dashboard.page";
import { APIDocumento } from "../../api/api_documento";
import { APIResponse } from "../../model/apiresponse";
import { APISubproceso } from "../../api/api_subproceso";
import { Archivo } from "../../model/archivo";
import { Col } from "antd";
import { Documento } from "../../model/documento";
import { FileExcelFilled } from "@ant-design/icons";
import { FileImageFilled } from "@ant-design/icons";
import { FilePdfFilled } from "@ant-design/icons";
import { FileWordFilled } from "@ant-design/icons";
import { Fragment } from "react";
import { Modal } from "antd";
import { Row } from "antd";
import { Space } from "antd";
import { Subproceso } from "../../model/subproceso";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import DocumentoContext from "../../provider/documento-provider";
import DocumentoWidget from "../widgets/documento.widget";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import NoDataComponent from "../components/nodata.component";
import SubprocesoContext from "../../provider/subproceso-provider";

function DocumentosPage() {
  const [_documentos, _setDocumentos] = useState<Documento[]>([]);
  const [_exists, _setExists] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_subproceso, _setSubproceso] = useState<Subproceso>();
  const _apiDocumento = useContext<APIDocumento>(DocumentoContext);
  const _apiSubproceso = useContext<APISubproceso>(SubprocesoContext);
  const { _codigo } = useParams();
  const { _token } = _getSession();
  const { _usuario } = _getSession();
  const { Title } = Typography;
  let _navigate = useNavigate();

  const _obtenerSubproceso = useCallback(async () => {
    let _result: APIResponse = await _apiSubproceso._obtenerSubproceso(
      _codigo!,
      _token
    );
    if (_result.success) {
      _setSubproceso(_result.data);
    }
  }, [_apiSubproceso, _codigo, _token]);

  const _listarDocumentosPorUsuario = useCallback(
    async (_usuario: Usuario) => {
      let _result: APIResponse =
        await _apiDocumento._listarDocumentosPorUsuario(
          _usuario.codigo,
          parseInt(_codigo!),
          _token
        );
      if (_result.codigo === 401) {
        _modal.error({
          title: Global.NOMBRE_PROYECTO,
          content: _result.message,
          centered: true,
          onOk: () => {
            localStorage.clear();
            _navigate("/login");
          },
        });
      } else if (_result.success) {
        _setExists(true);
        _setDocumentos(_result.data);
      } else {
        _setExists(false);
        _setDocumentos([]);
      }
    },
    [_apiDocumento, _codigo, _modal, _navigate, _token]
  );

  const _marcarDocumento = useCallback(
    async (_documento: Documento) => {
      await _apiDocumento._marcarDocumento(
        _usuario.codigo,
        _documento.codigo,
        _token
      );
      _listarDocumentosPorUsuario(_usuario);
    },
    [_apiDocumento, _listarDocumentosPorUsuario, _token, _usuario]
  );

  const _renderArchivos = (_documento: Documento) => {
    return (
      <Space key={`archivos_lista_${_documento.codigo}`}>
        {_documento.archivos.map((_archivo: Archivo, _index: number) => {
          return (
            <a
              key={`enlace_a_${_archivo.codigo}`}
              href={`${Global.URL_DOCUMENTOS}${_archivo.url}`}
              target="_blank"
              rel="noreferrer"
              onClick={
                () => {
                  _marcarDocumento(_documento);
                }
                // _archivo.tipo === "PDF"
                //   ? () => {
                //       _marcarDocumento(_documento);
                //     }
                //   : () => {
                //       console.log("ahdjshaj");
                //     }
              }
            >
              {_getIconFile(_archivo.tipo)}
            </a>
          );
        })}
      </Space>
    );
  };

  const _getIconFile = (_tipo: string) => {
    if (_tipo.toUpperCase() === "PDF") {
      return <FilePdfFilled className="icon-pdf" />;
    }

    if (_tipo.toUpperCase() === "XLSX" || _tipo.toUpperCase() === "XLSM" || _tipo.toUpperCase() === "XLS") {
      return <FileExcelFilled className="icon-excel" />;
    }

    if (_tipo.toUpperCase() === "DOCX" || _tipo.toUpperCase() === "DOCM" || _tipo.toUpperCase() === "DOC") {
      return <FileWordFilled className="icon-word" />;
    }

    if (
      _tipo.toUpperCase() === "PNG" ||
      _tipo.toUpperCase() === "JPG" ||
      _tipo.toUpperCase() === "JPEG"
    ) {
      return <FileImageFilled className="icon-imagen" />;
    }
  };

  useEffect(() => {
    _setMounted(true);
    _obtenerSubproceso();
    _listarDocumentosPorUsuario(_usuario);
    return () => {
      _setMounted(false);
    };
  }, [_listarDocumentosPorUsuario, _obtenerSubproceso, _usuario]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={5}>
            Subproceso: {_subproceso ? _subproceso.subproceso : ""}
          </Title>
          <Title level={2} className="nombre-subproceso-documentos">
            {_subproceso ? _subproceso.subproceso : ""}
          </Title>
        </Col>
        {!_exists ? (
          <NoDataComponent />
        ) : _documentos.length > 0 ? (
          _documentos.map((_documento: Documento, _index: number) => {
            return (
              <Col
                key={`documento${_index}`}
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={6}
                xxl={6}
                className="mb-50"
              >
                <DocumentoWidget
                  _documento={_documento}
                  _renderArchivos={_renderArchivos}
                />
              </Col>
            );
          })
        ) : (
          <LoadComponent />
        )}
      </Row>
      {_contextHolder}
    </Fragment>
  );
}

export default DocumentosPage;
