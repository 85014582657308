import "../../styles/pages/procesos.page.scss";
import { _getSession } from "./dashboard.page";
import { APIArea } from "../../api/api_area";
import { APIProceso } from "../../api/api_proceso";
import { APIResponse } from "../../model/apiresponse";
import { Area } from "../../model/area";
import { Col } from "antd";
import { Fragment } from "react";
import { Modal } from "antd";
import { Proceso } from "../../model/proceso";
import { Row } from "antd";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import AreaContext from "../../provider/area-provider";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import NoDataComponent from "../components/nodata.component";
import ProcesoContext from "../../provider/proceso-provider";
import ProcesoWidget from "../widgets/proceso.widget";

function ProcesoPage() {
  const [_area, _setArea] = useState<Area>();
  const [_exists, _setExists] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_procesos, _setProcesos] = useState<Proceso[]>([]);
  const _apiArea = useContext<APIArea>(AreaContext);
  const _apiProceso = useContext<APIProceso>(ProcesoContext);
  const { _codigo } = useParams();
  const { _token } = _getSession();
  const { _usuario } = _getSession();
  const { Title } = Typography;
  let _navigate = useNavigate();

  const _obtenerArea = useCallback(async () => {
    let _result: APIResponse = await _apiArea._obtenerArea(_codigo!, _token);
    if (_result.success) {
      _setArea(_result.data);
    }
  }, [_apiArea, _codigo, _token]);

  const _listarProcesosPorUsuario = useCallback(
    async (_usuario: Usuario) => {
      let _result: APIResponse = await _apiProceso._listarProcesosPorUsuario(
        _usuario.codigo,
        parseInt(_codigo!),
        _token
      );
      if (_result.codigo === 401) {
        _modal.error({
          title: Global.NOMBRE_PROYECTO,
          content: _result.message,
          centered: true,
          onOk: () => {
            localStorage.clear();
            _navigate("/login");
          },
        });
      } else if (_result.success) {
        _setExists(true);
        _setProcesos(_result.data);
      } else {
        _setExists(false);
        _setProcesos([]);
      }
    },
    [_apiProceso, _codigo, _modal, _navigate, _token]
  );

  const _toSubprocesos = (_proceso: Proceso) => {
    _navigate(
      `/${_proceso.area.area.toLocaleLowerCase()}/${_proceso.proceso.toLocaleLowerCase()}/${
        _proceso.codigo
      }`
    );
  };

  useEffect(() => {
    _setMounted(true);
    _obtenerArea();
    _listarProcesosPorUsuario(_usuario);
    return () => {
      _setMounted(false);
    };
  }, [_listarProcesosPorUsuario, _obtenerArea, _usuario]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={5}>&Aacute;rea: {_area ? _area.area : ""}</Title>
          <Title level={2} className="nombre-area-procesos">
            {_area ? _area.area : ""}
          </Title>
        </Col>
        {!_exists ? (
          <NoDataComponent />
        ) : _procesos.length > 0 ? (
          _procesos.map((_proceso: Proceso, _index: number) => {
            return (
              <Col
                key={`proceso${_index}`}
                xs={12}
                sm={12}
                md={8}
                lg={6}
                xl={6}
                xxl={6}
              >
                <ProcesoWidget
                  _proceso={_proceso}
                  _toSubprocesos={_toSubprocesos}
                />
              </Col>
            );
          })
        ) : (
          <LoadComponent />
        )}
      </Row>
      {_contextHolder}
    </Fragment>
  );
}

export default ProcesoPage;
